import * as React from 'react';
import SpeechesListByDay from './SpeechesListByDay';
import {Speech} from '../../models/Speech';
import {getNameFromNumber} from '../../utils/StringUtils';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
type SpeechesListProps = {
    startDateEventsMap: Map<string, Speech[]>
}
const EventDaysList: React.FC<SpeechesListProps> = ({startDateEventsMap}) => {
    return (
        <>
            {Array.from(startDateEventsMap.entries())
                .map((entry, index) => {
                    const formattedStartDate = entry[0];
                    const events = entry[1];

                    return (
                        <React.Fragment key={index}>
                            <h2 className="soul-space-stack-both-m h-color-primary-bold">
                                Day {getNameFromNumber(index)}: {formattedStartDate}
                            </h2>
                            <div className="l-card speech-flag-card">
                                <SpeechesListByDay speechesList={events}></SpeechesListByDay>
                            </div>
                            <div className="soul-space-stack-bottom-2-xl"></div>
                        </React.Fragment>
                    );
                })
            }
        </>
    );
}

export default EventDaysList;
