import * as React from 'react';
import MainNavbar from './mainNavbar/MainNavbar';
import Hero from './hero/Hero';
import SiteNavbar from './siteNavbar/SiteNavbar';
import Banner from './banner/Banner';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const Header: React.FC = () => (
    <>
        <MainNavbar/>
        <Hero/>
        <SiteNavbar/>
    </>
);

export default Header;
