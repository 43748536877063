import {Speaker, SpeakerInSpeechDAO} from './Speaker';
import {getFormattedStartDateFromDate, getFormattedTimeFromDate} from '../utils/DateUtils';
import Speakers from '../pages/speakers';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export interface SpeechDAO {
    id: string;
    relativePath: string;
    childMarkdownRemark: {
        frontmatter: {
            title: string;
            startDate: string;
            startTime: string;
            endDate: string;
            endTime: string;
            timezone: string;
            day: string;
            order: number;
            speakers: SpeakerInSpeechDAO[];
        };
    };
}

export class Speech {
    private readonly _id: string;
    private readonly _isTBA: boolean;
    private readonly _relativePath: string;
    private readonly _startDate: string;
    private readonly _startTime: string;
    private readonly _endDate: string;
    private readonly _endTime: string;
    private readonly _timezone: string;
    private readonly _day: string;
    private readonly _title: string;
    private readonly _order: number;
    private readonly _speakers: Speaker[];


    constructor(id: string, isTBA: boolean, relativePath: string, startDate: string,
                startTime: string, endDate: string, endTime: string, timezone: string,
                day: string, order: number, title: string, speakers: Speaker[]) {
        this._id = id;
        this._isTBA = isTBA;
        this._relativePath = relativePath;
        this._startDate = startDate;
        this._startTime = startTime;
        this._endDate = endDate;
        this._endTime = endTime;
        this._timezone = timezone;
        this._day = day;
        this._title = title;
        this._order = order;
        this._speakers = speakers;
    }

    static createTBA(): Speech {
        const id = '';
        const relativePath = '';
        const startDate = '';
        const startTime = '';
        const endDate = '';
        const endTime = '';
        const timezone = '';
        const day = '14 March';
        const title = 'TBA';
        const order = 1;
        const speakers: Speaker[] = [];
        return new Speech(id, true, relativePath, startDate, startTime, endDate,
            endTime, timezone, day, order, title, speakers);
    }

    static deserialize(data: SpeechDAO): Speech {
        const id = data.id;
        const relativePath = data.relativePath;
        const frontmatter = data.childMarkdownRemark.frontmatter;
        const startDate = frontmatter.startDate;
        const startTime = frontmatter.startTime;
        const endDate = frontmatter.endDate;
        const endTime = frontmatter.endTime;
        const day = frontmatter.day;
        const order = frontmatter.order;
        const timezone = frontmatter.timezone;
        const title = frontmatter.title;
        let speakers: Speaker[] = [];
        if (frontmatter.speakers) {
            speakers = frontmatter?.speakers?.map(Speaker.deserializeSpeakerInSpeech);
        }

        return new Speech(id, false, relativePath, startDate, startTime, endDate,
            endTime, timezone, day, order, title, speakers);
    }

    get id(): string {
        return this._id;
    }

    get isTBA(): boolean {
        return this._isTBA;
    }

    get relativePath(): string {
        return this._relativePath;
    }

    get formattedStartDateAndTime(): string {
        return `${this.formattedStartDate}, ${this.startTime}`;
    }

    get formattedStartDate(): string {
        return this.day;
    }

    get startDateTime(): Date {
        return new Date(this._startDate);
    }

    get startTime(): string {
        return this._startTime;
    }

    get endTime(): string {
        return this._endTime;
    }

    get timezone(): string {
        return this._timezone;
    }

    get day(): string {
        return this._day;
    }

    get order(): number {
        return this._order;
    }

    get title(): string {
        return this._title;
    }

    get speakers(): Speaker[] {
        return this._speakers;
    }
}
