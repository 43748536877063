import {FluidObject} from 'gatsby-image';
import {Speech, SpeechDAO} from './Speech';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export interface SpeakerInSpeechDAO {
    id: string;
    relativePath: string;
    childMarkdownRemark: {
        frontmatter: {
            firstName: string;
            lastName: string;
            jobTitle: string;
            organization: string;
            weight: number
            avatar: {
                childImageSharp: {
                    fluid: FluidObject
                }
            }
        }
    };
}

export interface SingleSpeakerQueryDAO {
    markdownRemark: {
        html: string
        frontmatter: {
            firstName: string;
            lastName: string;
            jobTitle: string;
            avatar: {
                childImageSharp: {
                    fluid: FluidObject
                }
            }
            organization: string;
            speeches: SpeechDAO[]
        }
    };
}

export class Speaker {
    private readonly _id: string;
    private readonly _relativePath: string;
    private readonly _firstName: string;
    private readonly _lastName: string;
    private readonly _jobTitle: string;
    private readonly _organization: string;
    private readonly _weight: number;
    private readonly _avatar: FluidObject;
    private readonly _speeches: Speech[];


    constructor(id: string, relativePath: string, firstName: string,
                lastName: string, jobTitle: string, organization: string,
                weight: number, avatar: FluidObject, speech: Speech[]) {
        this._id = id;
        this._relativePath = relativePath;
        this._firstName = firstName;
        this._lastName = lastName;
        this._jobTitle = jobTitle;
        this._organization = organization;
        this._weight = weight;
        this._avatar = avatar;
        this._speeches = speech;
    }

    static deserializeSpeakerInSpeech(data: SpeakerInSpeechDAO): Speaker {
        const id = data.id;
        const relativePath = data.relativePath;
        const frontmatter = data.childMarkdownRemark.frontmatter;
        const firstName = frontmatter.firstName;
        const lastName = frontmatter.lastName;
        const jobTitle = frontmatter.jobTitle;
        const organization = frontmatter.organization;
        const weight = frontmatter.weight;
        const fluidObject = frontmatter?.avatar?.childImageSharp?.fluid;
        const speeches: Speech[] = [];

        return new Speaker(id, relativePath, firstName, lastName, jobTitle,
            organization, weight, fluidObject, speeches);
    }

    static deserializeSingleSpeakerQuery(data: SingleSpeakerQueryDAO): Speaker {
        const frontmatter = data.markdownRemark.frontmatter;
        const id = '';
        const relativePath = '';
        const firstName = frontmatter.firstName;
        const lastName = frontmatter.lastName;
        const jobTitle = frontmatter.jobTitle;
        const weight = 666;
        const organization = frontmatter.organization;
        const fluidObject = frontmatter.avatar.childImageSharp.fluid;
        let speeches: Speech[] = [];
        if (frontmatter.speeches) {
            speeches = frontmatter.speeches.map(Speech.deserialize);
        }

        return new Speaker(id, relativePath, firstName, lastName, jobTitle,
            organization, weight, fluidObject, speeches);
    }

    get fullName(): string {
        return `${this._firstName} ${this._lastName}`;
    }

    get id(): string {
        return this._id;
    }

    get relativePath(): string {
        return this._relativePath;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get jobTitle(): string {
        return this._jobTitle;
    }

    get organization(): string {
        return this._organization;
    }

    get weight(): number {
        return this._weight;
    }

    get avatar(): FluidObject {
        return this._avatar;
    }

    get speeches(): Speech[] {
        return this._speeches;
    }
}
