import * as React from 'react';
import {graphql, StaticQuery} from 'gatsby';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const Hero: React.FC = () => (
<StaticQuery
    query={graphql`
        query RetrieveContent {
            file(name: {eq: "hero-content"}) {
                childIndexJson {
                    title
                    tdLogo {
                        publicURL
                    }
                    plane {
                        publicURL
                    }
                }
            }
        }
    `}
    render={(data) => {
        const getImage = (imageResult: any, alt?:string, imageClass?: string) => <img className={imageClass||''} src={imageResult.publicURL} alt={alt}/>;
        return (
            <div className="hero">
                <div className="centered-column soul-space-stack-both-3-xl" style={{backgroundImage: `url(${data.file.childIndexJson.plane.publicURL})`}}>
                    <div className="hero__content soul-space-stack-both-2-xl">
                        {getImage(data.file.childIndexJson.tdLogo, "ESTECO Tech Days", 'hero__logo')}
                        <div className="hero__payoff soul-space-stack-top-xl">
                            {data.file.childIndexJson.title}
                        </div>
                    </div>
                </div>
            </div>
        );
    }}
/>);

export default Hero;
