import * as React from 'react';
import {graphql} from 'gatsby';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import SEO from '../components/SEO/SEO';
import EventDaysList from '../components/agenda/EventDaysList';
import {Speech, SpeechDAO} from '../models/Speech';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface AgendaPageProps {
    data: {
        allFile: {
            edges: {
                node: SpeechDAO
            }[]
        }
    };
}

const AgendaPage: React.FC<AgendaPageProps> = ({data}) => {
    const eventsGroupedByStartDate = new Map<string, Speech[]>();

    data.allFile.edges
        .map(edge => Speech.deserialize(edge.node))
        .sort((a, b) => (a.order < b.order) ? -1 : 1)
        .forEach(speech => {
            const startDate = speech.formattedStartDate;
            if (!eventsGroupedByStartDate.has(startDate)) {
                eventsGroupedByStartDate.set(startDate, []);
            }
            // @ts-ignore
            eventsGroupedByStartDate.get(startDate).push(speech);
        });

    return (
        <SingleColumnLayout>
            <SEO title="TD23" description="Join us in person in Tucson on 14 and 15 March to discover more about ESTECO VOLTA applications in the Aerospace & Defense industry"/>
            <div className="centered-column">
                <h1 className="page-title">Agenda</h1>
                <EventDaysList startDateEventsMap={eventsGroupedByStartDate}></EventDaysList>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
query SpeechesQuery {
    allFile(filter: {relativeDirectory: {eq: "speeches"}, extension: {eq: "md"}}) {
        edges {
            node {
                childMarkdownRemark {
                    frontmatter {
                        title
                        startDate
                        startTime
                        endDate
                        endTime
                        timezone
                        day
                        order
                        speakers {
                            childMarkdownRemark {
                                frontmatter {
                                    firstName
                                    lastName
                                    jobTitle
                                    organization
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export default AgendaPage;
