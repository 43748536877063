import * as React from 'react';
import Flag from '../soul/layout/flag/Flag';
import {Speaker} from '../../models/Speaker';
import {Speech} from '../../models/Speech';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
type SpeechFlagProps = {
    speechesList: Speech[];
}

const SpeechesListByDay: React.FC<SpeechFlagProps> = ({speechesList}) => {
    const getSpeakersNames = (speech: Speech) => speech.speakers
        .map(one => {
            const slug = one.fullName
                .toLowerCase()
                .replace(/[^A-Za-z0-9\s]/g,'')
                .replace(/\s/g, '-');

            return `<a href="/speakers/${slug}">${one.fullName}</a>`;
        })
        .join(' · ');
    const getSpeakersCompanies = (speech: Speech) => speech.speakers
        .map((one: Speaker) => one.organization)
        .reduce((acc: string[], currentValue: string) => acc.includes(currentValue) ? [...acc] : [...acc, currentValue], [])
        .join(' · ');
    const getInfoSpeech = (speech: Speech) => {
        let spacingClass = 'soul-space-stack-top-xs';
        let speakerDetails = '';

        if (speech.speakers && speech.speakers.length > 0) {
            speakerDetails = `<strong>${getSpeakersNames(speech)}</strong> · ${getSpeakersCompanies(speech)}`;
        } else {
            spacingClass = 'soul-space-stack-both-s';
        }

        return <React.Fragment>
            <div className={'soul-font-size-m h-text-bold ' + spacingClass}>{speech.title}</div>
            <p className="a-label a-label--reduced" dangerouslySetInnerHTML={{__html: speakerDetails}}></p>
        </React.Fragment>;
    };

    return (
        <>
            {speechesList.map((speech: Speech, index) => (
                <React.Fragment key={index}>
                    <Flag>
                        <div className="soul-navigation-card__image soul-space-inset-m">
                            <p>{speech.startTime} - {speech.endTime}
                                <span className="soul-font-size-xs a-label--reduced">&nbsp;{speech.timezone}</span></p>
                        </div>
                        <div>
                            <div className="l-card__body soul-space-inset-m">
                                {getInfoSpeech(speech)}
                            </div>
                        </div>
                    </Flag>
                    {
                        index !== speechesList.length - 1
                            ? <div className="ew-divider"></div>
                            : ''
                    }
                </React.Fragment>
            ))}
        </>
    );
};

export default SpeechesListByDay;
